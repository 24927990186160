import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Privacy And Security</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Privacy and security:</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="blog-item">
                  <div style={bgImg} className="blog-block">
                    <div className="blog-box">
                      <div className="overflow-hidden">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/readmore/3.jpg"
                            }
                            alt="blog"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="blog-text">
                    <a
                      href="javascript"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <br />
                      <h2 className="blog-head">Privacy and security: </h2>
                    </a>
                    <div className="blog-divider"></div>
                    <div className="blog-description">
                      <p>
                        It’s very common for chat messenger app users to worry
                        about their safety while exchanging sensitive
                        information online. Ring Talk is a chatting app that
                        caters to all or most of the security concerns, that
                        might keep you from using an online chat app. The app
                        protects your data in the following ways:
                      </p>
                      <h3>1. Carries your messages safely:</h3>
                      <p>
                        Ring Talk uses an encryption method that is considered
                        suitable by International standards. The app carries
                        your messages safely between transmitting devices and
                        servers. This makes sure no third party can view your
                        messages. Messages are not stored on the app and are
                        deleted as soon as they reach the intended recipient.
                        The only time your messages will reappear is when you
                        request for back- up.
                      </p>
                      <h3>2. Asks for your permission:</h3>
                      <p>
                        Ring Talk does not receive unlicensed data, nor does it
                        process any such data or share it. The app collects data
                        for providing services, and that too when the user
                        allows it. For instance, the chat messenger app will ask
                        for your permission to access your contacts, pictures,
                        videos, and other data stored on your phone. The
                        chatting app does not ask users to share personal
                        information for advertising purposes.
                      </p>
                      <h3>3. Anyone can use it:</h3>
                      <p>
                        There are no restrictions as to who can use Ring Talk.
                        Youngsters and adults are all welcome to use the app,
                        although it is still advisable to discourage kids from
                        using such apps before the age of thirteen. But there is
                        no discrimination on religion, caste, creed, gender, or
                        ethnicity. You can download and use the app no matter
                        what your sexual orientation.
                      </p>
                      <h3>4. Confess with an open heart:</h3>
                      <p>
                        Everyone enjoys{" "}
                        <a
                          href="/group-chats"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          chatting
                        </a>{" "}
                        openly with a friend, family member, or partner once in
                        a while. People want to be able to confess where they
                        went wrong or share their embarrassing moments. This
                        helps them cleanse their system but they can only do so
                        when they’re sure no one’s going to eavesdrop on them.
                        Ring Talk ensures private conversations stay private.
                        You just need to be sure you’re talking to the right
                        person!
                      </p>
                      <h3>5. Written conversations stay safe:</h3>
                      <p>
                        Once your messages are delivered to the intended
                        recipients, they are not stored on Ring Talk servers.
                        With end-to-end encryption, you can be sure your written
                        conversations only get stored on your device and no one
                        else has access to them. You can send crazy and
                        impulsive messages to your friends but make sure this
                        person is hundred percent trustworthy!
                      </p>
                      <h3>6. Distance does not hinder privacy:</h3>
                      <p>
                        Some users assume that when they make online{" "}
                        <a
                          href="/clear-calls"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          calls
                        </a>{" "}
                        to loved ones living far away, the confidentiality of
                        their conversation could be compromised. However, this
                        is not the case as far as Ring Talk is concerned.
                        Whether you're making local calls or international
                        calls, your calls remain private and no third party can
                        intervene. You can safely make both audio and video{" "}
                        <a
                          href="/clear-calls"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          calls
                        </a>{" "}
                        to other app users.
                      </p>
                      <h3>7. Mischief mongers are kept at bay:</h3>
                      <p>
                        Some people are just plain snoopy! Ring Talk lets you
                        keep unnecessary gossip queens and stalkers at bay. You
                        can use the app settings if don't want some of your
                        contacts to see when you last logged in to your account.
                        You have a right to your privacy and no one should be
                        allowed to keep tabs on you unnecessarily.
                      </p>
                      <h3>8. Lets you take your time to respond:</h3>
                      <p>
                        Ring Talk keeps you from falling prey to
                        misunderstandings. Some people seriously lack patience.
                        As soon as they find out you've read their messages,
                        they expect you to respond immediately. If you don't
                        reply instantly they start resenting you. The app lets
                        you decide whether or not you want people to see that
                        you’ve read their messages. This way you can take your
                        time to respond without offending anyone.
                      </p>
                      <p>
                        Ring Talk is a chat messenger app that aims to create a
                        safe environment for communication. It enables users to
                        make{" "}
                        <a
                          href="/clear-calls"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          calls
                        </a>{" "}
                        and send messages without hesitation. The{" "}
                        <a
                          href="/group-chats"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          chatting
                        </a>{" "}
                        app also lets users make their accounts more secure by
                        using a password, fingerprint lock, or face ID. This
                        way, even if your device gets into the wrong hands, no
                        one can access your account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>
                    © Ring Tok 2022. All rights are reserved.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
