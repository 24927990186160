import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Terms And Conditions</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Terms and Conditions:</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="blog-item">
                <div className="blog-text">
                  <a
                    href="javascript"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  ></a>

                  <div className="blog-description">
                    <section class="hero text">
                      <div class="container-sm">
                        <div class="container">
                          <p>
                            These Terms of Service (“Terms”) govern your use of
                            the Ring Tok App, Ring Tok Website, and any
                            services, features, and systems of products
                            presented by Ring Tok, as detailed below. These
                            Terms form a legally binding agreement between you
                            and Ring Tok Media (“Ring Tok “, “our “, “we” or “us
                            “).
                          </p>
                          <p>
                            Please read them carefully, we've tried our best to
                            strip the legalese from the Terms, however, there
                            are places where they'll still read like a
                            traditional agreement as they are a legally binding
                            settlement among you and Ring Tok. These Terms
                            incorporate and are governed by the Acceptance Use
                            of Policy “AUP” and the Ring Tok Privacy Policy.
                            Here, you confirm that you have read and understood
                            the terms and that by installing the Ring Tok app,
                            accessing or using our services, you agree to be
                            bound by the terms. You agree to comply with all
                            applicable laws and regulations when using the Ring
                            Tok app and services and you acknowledge that these
                            terms constitute a binding and enforceable legal
                            electronic contract between you and Ring Tok. Please
                            do not access the services or install the Ring Tok
                            app if you do not agree to all or any part of the
                            terms.
                          </p>

                          <h4>About our Services:</h4>
                          <ul>
                            <li>
                              <h4>Privacy And Security Principles:</h4>
                              <p>
                                Since we started Ring Tok, we've built our
                                Services with strong privacy and security
                                principles.
                              </p>
                            </li>
                            <li>
                              <h4>Connecting With Other People:</h4>
                              <p>
                                We provide, and always try to improve, ways for
                                you to communicate with other Ring Tok users
                                including through messages, voice and video
                                calls, sending images and video, and sharing
                                your location with others when you want. We may
                                provide a convenient platform that enables you
                                to send and receive money to or from other users
                                across our platform. Ring Tok works with
                                partners, service providers, and affiliated
                                companies to help us provide ways for you to
                                connect with their services.
                              </p>
                            </li>
                            <li>
                              <h4>Improvement in Our Services:</h4>
                              <p>
                                We examine the way you make use of Ring Tok, for
                                you to enhance our Services, along with who uses
                                Ring Tok to measure the effectiveness and
                                distribution of their offerings and messages.
                                Ring Tok makes use of the facts it has and also
                                works with partners, service providers, and
                                affiliated organizations to do this.
                              </p>
                            </li>
                            <li>
                              <h4>Safety, Security, And Integrity:</h4>
                              <p>
                                Ring Tok cannot guarantee that the Services will
                                always function without disruptions, delay or
                                errors because we have no control of the
                                third-party networks carrying our Services. We
                                try hard to keep our Services a safe place for
                                all users. But we can’t guarantee it. A number
                                of factors may impact the quality of your
                                communications and use of the Services, and may
                                result in the failure of your communications.
                                Ring Tok takes no responsibility for any
                                disruption, interruption or delay caused by any
                                failure of or inadequacy in any of these items
                                or any other items over which we have no
                                control. Up-to-date evidence on call set up time
                                and unsuccessful calls (through your list of
                                calls made and attempted) is available through
                                your User Account. We work to protect the
                                safety, security, and integrity of our Services.
                                This includes appropriately dealing with abusive
                                people and activity violating our Terms. We work
                                to prohibit misuse of our Services including
                                harmful conduct towards others, violations of
                                our Terms and policies, and address situations
                                where we may be able to help support or protect
                                our community.
                              </p>
                            </li>
                            <li>
                              <h4>Enabling Access to Our Services:</h4>
                              <p>
                                To operate our global Services, we need to store
                                and distribute content and information in data
                                centers and systems around the world, including
                                outside your country of residence. The use of
                                this global infrastructure is necessary and
                                essential to provide our Services. This
                                infrastructure may be owned or operated by our
                                service providers including affiliated
                                companies.
                              </p>
                            </li>
                            <li>
                              <h4>NO ACCESS TO EMERGENCY SERVICES:</h4>
                              <p>
                                There are important differences between our
                                Services and your mobile phone and a fixed-line
                                telephone and SMS services. Our Services do not
                                provide access to emergency services or
                                emergency services providers, including the
                                police, fire departments, or hospitals, or
                                otherwise connect to public safety answering
                                points. You should ensure you can contact your
                                relevant emergency services providers through a
                                mobile phone, a fixed-line telephone, or other
                                service.
                              </p>
                            </li>
                          </ul>
                          <h3>Acceptable Use of Service</h3>
                          <h4>Terms And Policies:</h4>
                          <p>
                            You must use our Services according to our Terms and
                            posted policies. If you violate our Terms or
                            policies, we may take action with respect to your
                            account, including disabling or suspending your
                            account and, if we do, you agree not to create
                            another account without our permission. Disabling or
                            suspending your account will be in accordance with
                            the ‘Termination” section below.
                          </p>

                          <h4>Keeping Your Account Secure:</h4>
                          <p>
                            You are responsible for keeping your device and your
                            Ring Tok account safe and secure, and you must
                            notify us promptly of any unauthorized use or
                            security breach of your account or our Services.
                          </p>

                          <h4>Harm To Our Users or Ring Tok:</h4>
                          <p>
                            You should not (or assist others to) directly,
                            indirectly, thru automatic or different means,
                            access, use, copy, adapt, modify, prepare derivative
                            works based upon, distribute, license, sublicense,
                            transfer, display, perform, or otherwise make the
                            most our Services in impermissible or unauthorized
                            manners, or in methods that burden, impair, or harm
                            us, our Services, systems, our customers, or others,
                            which includes which you should not directly or thru
                            automatic means:
                          </p>

                          <ul>
                            <li>
                              Reverse engineer, alter, modify, create spinoff
                              works from, decompile, or extract code from our
                              Services;
                            </li>
                            <li>
                              Send, store, or transmit viruses or other
                              dangerous system code thru or onto our Services;
                            </li>
                            <li>
                              Gain or try and benefit unauthorized access to our
                              Services or systems;
                            </li>
                            <li>
                              Interfere with or disrupt the safety, security,
                              confidentiality, integrity, availability, or
                              performance of our Services;
                            </li>
                            <li>
                              Create accounts for our Services thru unauthorized
                              or automatic means;
                            </li>
                            <li>
                              Acquire information of or about our users in any
                              impermissible or unauthorized manner;
                            </li>
                            <li>
                              Sell, resell, rent, or charge for our Services or
                              data obtained from us or our Services in an
                              unauthorized manner;
                            </li>
                            <li>
                              Distribute or make our Services available over a
                              network where they may be used by multiple gadgets
                              at the same time, except as authorized through
                              tools we've expressly provided via our Services;
                            </li>
                            <li>
                              Create software program or APIs that feature
                              significantly similar to our Services and offer
                              them to be used via way of means of third parties
                              in an unauthorized manner;
                            </li>
                            <li>
                              Misuse any reporting channels, such as by
                              submitting fraudulent or groundless reports or
                              appeals.
                            </li>
                          </ul>
                          <h4>Account Security:</h4>
                          <p>
                            You are responsible for keeping your device and your
                            Ring Tok account safe and secure, and you must
                            notify us promptly of any unauthorized use or
                            security breach of your account or our Services.
                          </p>

                          <h4>Termination:</h4>
                          <p>
                            We may add or remove features, products, or
                            functionalities, and we may also suspend or stop the
                            Services, with or without notice. We aspire that you
                            will always continue to share good vibes through the
                            use of our Services, but you may choose to terminate
                            your relationship with Ring Tok at any time.
                          </p>
                          <p>
                            If you are using our free Services, you may
                            terminate your relationship with Ring Tok by simply
                            ceasing to use the Services. Where permissible under
                            applicable law, Ring Tok has the right to terminate
                            your rights under our Services without notice,
                            including, without limitation, by blocking you,
                            suspending or terminating your Account, including,
                            and without derogating from our general right, if
                            you are in breach of our Terms or other policies
                            (including our Acceptance Use of Service) or if we
                            decide not to provide the Services or any part
                            thereof in certain locations or to certain devices.
                            Ring Tok reserves the right to modify or
                            discontinue, temporarily or permanently, all or any
                            part of its Services or any software, facilities,
                            and services on its Services, with or without notice
                            or to establish general guidelines and limitations
                            on their use.
                          </p>
                          <h4>Third party goods and services:</h4>
                          <p>
                            Certain Services may display, include or make
                            available content, data, information, applications,
                            features or materials from third parties or provide
                            links to certain third-party websites. Through the
                            Chatbots you may even be able to purchase goods and
                            services provided by third party merchants. Our
                            Services may link you to other sites on the Internet
                            and third-party partners which cooperate with us to
                            provide you certain services. These other sites are
                            not under the control of Ring Tok, and you
                            acknowledge that (whether or not such sites are
                            affiliated in any way with Ring Tok)
                          </p>
                          <p>
                            Ring Tok cannot ensure that you will be satisfied
                            with any products or services that you purchase from
                            any third-party site that links to or from Ring Tok
                            since the third-party sites are owned and operated
                            by independent retailers. We strongly encourage you
                            to make whatever investigation you feel necessary or
                            appropriate before proceeding with any online
                            transaction with any of these third-parties.
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-section">
                  <p>© Ring Tok 2022. All rights are reserved.</p>
                  <p>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
