import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`,
  });

  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/contact.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/contact.png)`,
      });
  }, [color]);

  const form = useRef();

  const sendEmail = (e) => {
    console.log("in email Function");
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wzdb0rg",
        "template_jghkyvs",
        form.current,
        "1WqmCHkxgoTX5Ywrn"
      )
      .then(
        (result) => {
          alert("Message Successfully Sent");
          console.log(result.text);
        },
        (error) => {
          alert("Message Failed to Sent");
          console.log(error.text);
        }
      );
    e.target.reset();
    // window.location.reload()
  };

  return (
    <section id="contact" className="contact" style={bgImg}>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>contact </span>information
                </h2>
                <form ref={form} onSubmit={sendEmail} className="theme-form">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="your name"
                          required="required"
                          name="user_name"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="phone"
                          required="required"
                          name="user_number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="email address"
                      required="required"
                      name="user_email"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="message"
                      name="message"
                      required="required"
                    ></textarea>
                  </div>
                  <div className="form-button">
                    <input
                      type="submit"
                      value="Send"
                      className="btn btn-custom theme-color"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            <div className="contact-right">
              <img
                src="assets/images/Contact-info.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
