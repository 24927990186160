import React, { useEffect, useState } from "react";

const Feature = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)`,
      });
  }, [color]);

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`}
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/222.png`}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  features of<span> RING TOK</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`}
                        alt="icon"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3 style={{ fontWeight: "bold" }}>
                        Private and group chats:
                      </h3>
                    </div>
                    <div>
                      <p>
                        With Ring Tok, you can chat privately with one
                        individual or have an online party using the group chat
                        feature.{" "}
                        <a
                          style={{ color: "#ED5B08", fontWeight: "bold" }}
                          href="/group-chats"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                        alt="icon"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3 style={{ fontWeight: "bold" }}>
                        Crystal clear calls:
                      </h3>
                    </div>
                    <div>
                      <p>
                        With Ring Tok, you’ll have a ball, while making calls to
                        friends and family! You can make one-to-one or group
                        calls with the app.{" "}
                        <a
                          style={{ color: "#ED5B08", fontWeight: "bold" }}
                          href="/clear-calls"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                        alt="icon"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3 style={{ fontWeight: "bold" }}>
                        Translated messages:
                      </h3>
                    </div>
                    <div>
                      <p>
                        Ring Tok has a chat translation feature that can
                        translate audio and text messages. It translates the
                        messages you receive, within a matter of seconds.{" "}
                        <a
                          style={{ color: "#ED5B08", fontWeight: "bold" }}
                          href="/translated-messages"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`}
                        alt="icon"
                      />
                    </div>
                    <div className="feature-subtitle">
                      <h3 style={{ fontWeight: "bold" }}>
                        Privacy and security:
                      </h3>
                    </div>
                    <div>
                      <p>
                        You can feel confident while texting with Ring Tok.no
                        third party can gain access to any information you send
                        or receive. This is a valuable feature for privacy
                        lovers.{" "}
                        <a
                          style={{ color: "#ED5B08", fontWeight: "bold" }}
                          href="/security"
                        >
                          read more
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
