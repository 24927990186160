import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
import Tilt from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
const HomeOne = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`,
  });
  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/1.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/1.png)`,
      });
  }, [color]);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");
  return (
    <div>
      <Helmet>
      <meta name="Description" content="Ring Tok is a secure, simple, fast, fun and Pure instant messaging and Calling App. It is synced across all your devices with no subscription fee. " />
        <title>Ringtok | Home</title>
      </Helmet>
      <Menu homePage="home-one" />
      <section id="home" className="home" style={bgImg}>
        <div className="home-decor">
          <div className="home-circle1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`}
              alt=""
            />
          </div>
          <div className="home-circle2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`}
              alt=""
            />
          </div>
          <div className="home-circle3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`}
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logoimage.png`}
                    alt="caption-img"
                    className="caption-img"
                  />
                  <h1>
                    <span className="f-bold">RING </span>
                    <span className="f-bold f-color">TOK</span>
                  </h1>
                  <h3 className="feature-subtitle">
                    Fast and secure messaging, high quality voice and video
                    calls
                  </h3>
                  <p>
                    Experience global unlimited messaging and calling with RING
                    TOK.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <div className="home-right">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/mobile.png`}
                    className="img-fluid"
                    alt="mobile"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>

      <About />

      <Feature />

      <ScreenShot />

      <Contact />

      <Footer />
    </div>
  );
};

export default HomeOne;
