import "./App.css";
import PageNotFound from "./Pages/404";
import Download from "./components/Ak";
import React, { useEffect } from "react";
import HomeOne from "./HomeOne";
import { Route, Switch } from "react-router-dom";
import Security from "./components/Security";
import ClearCalls from "./components/ClearCalls";
import TranslatedMessages from "./components/TranslatedMessages";
import GroupChats from "./components/GroupChats";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacypolicy from "./components/Privacypolicy";

function App() {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  localStorage.setItem("color", "color-1");
  return (
    <div className="App">
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeOne} />
        <Route
          path={`${process.env.PUBLIC_URL}/TermsAndConditions`}
          component={TermsAndConditions}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/Privacypolicy`}
          component={Privacypolicy}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/group-chats`}
          component={GroupChats}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/translated-messages`}
          component={TranslatedMessages}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/clear-calls`}
          component={ClearCalls}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/security`}
          component={Security}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/404`}
          component={PageNotFound}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/download`}
          component={Download}
        />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default App;
