import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Privacy Policy</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Privacy Policy</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="blog-item">
                <div className="blog-text">
                  <div className="blog-description">
                    <section className="hero text">
                      <div className="container-sm">
                        <div className="container">
                          <h4>Introduction:</h4>

                          <p>
                            This Privacy Policy set out how we, Ring Tok Inc.
                            (“Ring Tok”), use and guard your personal data that
                            you provide to us, or that is otherwise received or
                            generated thru manner of approach of us, in
                            connection with your use of our cloud-based
                            messaging services (the “Services”). For the
                            features of this Privacy Policy, ‘Ring Tok’, ‘we’,
                            ‘us’ and ‘our’ refers to Ring Tok, and ‘you’ refers
                            to you, the person of the Services.
                          </p>
                          <h4>Privacy Principles:</h4>
                          <p>
                            Ring Tok has two Basic
                            <b>concepts in relation to gathering</b> and
                            processing
                            <b>personal records</b>:
                          </p>
                          <ul>
                            <li>•End-to-End security</li>
                            <li>
                              We <b>do not</b> use your <b>records to reveal</b>{" "}
                              your ads.
                            </li>
                            <li>
                              We <b>most effectively save</b> the <b>records</b>{" "}
                              that Ring Tok <b>needs to feature</b> as a{" "}
                              <b>stable</b> and
                              <b>feature-rich</b> messaging service.
                            </li>
                          </ul>
                          <h4>Table of Contents:</h4>
                          <p>This Privacy Policy explains the following:</p>
                          <ul>
                            <li>
                              • What kind of personal information do we collect
                              and hold?
                            </li>
                            <li>
                              • Why do we collect, hold, use and disclose
                              personal information?
                            </li>
                            <li>•How do we collect personal information?</li>
                            <li>
                              •How do we maintain your personal information?
                            </li>
                            <li>•Processing your Personal Data</li>
                            <li>
                              •Who your private information can be shared with;
                              and?
                            </li>
                            <li>
                              •Your rights concerning your private information.
                            </li>
                          </ul>
                          <h4>
                            What kind of personal information do we collect and
                            hold
                          </h4>
                          <p>
                            Ring Tok is a communication service. The operation
                            we use in our Ring Tok app and service involves
                            collection of information about you where the type
                            of such data depends on the type of service and
                            interaction with us. We also automatically collect
                            certain information from our user ‘device’. We
                            explain what type of information we collected and
                            how we collected. Data gathered and provided via
                            registration or collaborating in Ring Tok activities
                          </p>
                          <h6>Identifiers, including:</h6>
                          <ul>
                            <li>
                              • Your mobile number (along with mobile
                              country/network code);
                            </li>
                            <li>
                              • Your personal details, (e.g., username), in case
                              you provided;
                            </li>
                            <li>
                              • Your contact details (e.g., e-mail address), in
                              case you provided them;
                            </li>
                            <li>
                              • Your account login details, which include your
                              username and any of your password or security
                              pin-code, that you have chosen, if any;
                            </li>
                            <li>•Device identifiers, as described below.</li>
                            <li>
                              • Your photo, if you provided it (consisting of
                              any avatar, etc.).
                            </li>
                            <li>
                              •Your interests, if you selected to provide them
                              to us.
                            </li>
                            <li>
                              • Your phone address book, which means, the names,
                              the phone numbers and the favorites listed out of
                              your contact lists, in case you permitted access
                              to them.
                            </li>
                            <li>
                              • We don't need to recognize your real name,
                              gender, age, or what you like.
                            </li>
                            <li>
                              • We do not require your display screen name to be
                              your real name.
                            </li>
                          </ul>
                          <p>
                            Data we gather automatically from your device e,g
                            information collected via cookies and other tool
                            identifying technologies (Tracking and cookies
                            technology)
                          </p>
                          <ul>
                            <li>
                              •<b>Device identifiers</b> which means identifiers
                              for devices you have used to access our
                              application, together with IP addresses,
                              manufacturer’s name, device model number,
                              operating system, carrier network,
                              advertising-related identifiers, and MAC
                              identifiers.
                            </li>
                            <li>
                              •<b>Electronic network</b> activity data, along
                              with information saved on log files, when you
                              access our Service and internet site for example,
                              including access time and date stamp, pages
                              viewed, IP address, the pages that directed you to
                              our website, records related to errors and crash
                              reports.
                            </li>
                            <li>
                              •<b>Activity records</b> regarding your
                              utilization of Services, along with connection
                              status, whether or not you've got received and
                              seen messages sent to you, if you are currently on
                              another name and records related to the calls and
                              messages that you send and receive, such as length
                              of the call, who called who, who messaged who, and
                              at what time, your personal preferences related to
                              such utilization (for example how often, for how
                              long, what alternatives are selected, etc.).
                            </li>
                          </ul>
                          <p>Such activity data may also include:</p>
                          <ul>
                            <li>
                              • We may gather data about hyperlinks you visit
                              via any of your messages; and with respect to your
                              activity in communities – records about the
                              communities you have visited or followed, messages
                              you have liked, messages you have sent, the
                              content you have viewed;
                            </li>
                            <li>
                              •Items you searched and shared through Chat
                              Extensions;
                            </li>
                          </ul>
                          <h4>Geolocation Data:</h4>

                          <ul>
                            <li>
                              • In General location, we use IP addresses to
                              collect additional information (e.g., phone number
                              area codes), to estimate your general location
                              (e.g., city level).
                            </li>
                            <li>
                              • GPS based location, we will collect the GPS
                              location for very specific purposes.
                            </li>
                          </ul>

                          <br />

                          <h4>Your Email Address:</h4>
                          <p>
                            When you enable 2-step-verification to your account
                            or store files using the Ring Tok Passport feature,
                            you could prefer to set up a password recovery
                            email. This address will only be used to send you a
                            password recovery code if you forget it. That's
                            right: no advertising or “we miss you” bullshit.
                          </p>
                          <h4>Your Messages:</h4>
                          <ul>
                            <li>
                              <h4>Cloud Chat:</h4>
                              <p>
                                Ring Tok is a cloud service. We store messages,
                                photos, videos and documents from your cloud
                                chats on our servers so that you can access your
                                data from any of your devices anytime without
                                having to rely on third-party backups. All data
                                is stored heavily encrypted and the encryption
                                keys in each case are stored in several other
                                data centres. This way local engineers or
                                physical intruders cannot get access to user
                                data.
                              </p>
                            </li>
                            <li>
                              <h4>Public Chats:</h4>
                              <p>
                                In addition to private messages, Ring Tok also
                                supports Community. All public chats are cloud
                                chats. Like everything on Ring Tok, the data you
                                post in public communities is encrypted, both in
                                storage and in transit — but everything you post
                                in public will be accessible to everyone.
                              </p>
                            </li>
                            <li>
                              <h4>Phone Number and Contacts:</h4>
                              <p>
                                Ring Tok uses phone numbers as unique
                                identifiers so that it is easy for you to switch
                                from SMS and other messaging apps and retain
                                your social graph. We ask your permission before
                                syncing your contacts. We store your up-to-date
                                contacts in order to notify you as soon as one
                                of your contacts signs up for Ring Tok and to
                                properly display names in notifications. We only
                                need the number and name (first and last) for
                                this to work and store no other data about your
                                contacts. Our automatic algorithms can also use
                                anonymize sets of phone numbers to calculate the
                                approximate number of potential contacts an
                                unregistered phone number may have on Ring Tok.
                                When you open the 'Invite friends' interface, we
                                display the resulting statistics next to your
                                contacts to give you an idea of who could
                                benefit most from joining Ring Tok.
                                <br />
                                If you are using Android, Ring Tok will ask you
                                for permission to access your phone call logs.
                                If you grant this permission, Ring Tok will be
                                able verify your account by transmitting a phone
                                call instead of asking you to enter a code. Ring
                                Tok uses this permission only to confirm receipt
                                of the confirmation call by verifying the number
                                in the call log.
                              </p>
                            </li>
                          </ul>
                          <h4>Location Data:</h4>

                          <p>
                            If you share a location in a chat, this location
                            data is treated like other messages in cloud chat.
                          </p>
                          <p></p>
                          <ul>
                            <li>
                              •Loss and damage covered by your own insurance.
                            </li>
                            <li>
                              • If you share your Live location in any chat Ring
                              Tok will use your data to display your location to
                              those users with whom you are sharing it, even
                              when the app is closed – for as long as you keep
                              these optional features activated.
                            </li>
                          </ul>

                          <h4>
                            Why do we collect, hold, use and disclose personal
                            information?
                          </h4>
                          <p>
                            Our primary purpose in collecting information is to
                            provide you with a safe, smooth, efficient, and
                            customized experience. Provide communications, video
                            sharing and other products in particular to convey
                            the communications, pictures and videos you and
                            others make by means of the Ring Tok software and/or
                            the Ring Tok products. Change and customize Ring Tok
                            features and functionality. Provide other services
                            for you (as described when we collect the
                            information). Provide you with customer support and
                            troubleshoot problems. Compare information for
                            accuracy. Inform you about service updates and
                            faults. Request feedback or participation in on-line
                            surveys. Resolve disputes. Verify your identity.
                            Customize, measure, and improve Ring Tok software
                            and websites, as well as other Ring Tok products and
                            services. Improve our search functionality and help
                            you find and contact other Ring Tok users more
                            easily and accurately.
                          </p>
                          <h4>How do we maintain your personal information?</h4>

                          <ul>
                            <li>
                              <h4>Storing Data:</h4>
                              <p>
                                If you signed up for Ring Tok, your data is
                                stored in data centres. These are third-party
                                provided data centres in which Ring Tok rents a
                                designated space. However, the servers and
                                networks that sit inside these data centres and
                                on which your personal data is stored are owned
                                by Ring Tok. As such, we do not share your
                                personal data with such data centres. All data
                                is stored heavily encrypted so that local Ring
                                Tok engineers or physical intruders cannot get
                                access.
                              </p>
                            </li>
                            <li>
                              <h4>End-To-End Encrypted Data:</h4>
                              <p>
                                Your messages, media and files from Cloud chats,
                                as well as the contents of your calls and the
                                data you store in your Ring Tok Passport are
                                processed only on your device and on the device
                                of your recipient. Before this data reaches our
                                servers, it is encrypted with a key known only
                                to you and the recipient. While Ring Tok servers
                                will handle this end-to-end encrypted data to
                                deliver it to the recipient – or store it in the
                                case of Ring Tok Passport data, we have no way
                                of decoding the actual information. In this
                                case, we neither store nor process your personal
                                data, rather we store and process random
                                sequences of symbols that have no meaning
                                without the keys which we don’t have.
                              </p>
                            </li>
                            <li>
                              <h4>Retention:</h4>
                              <p>
                                Unless otherwise specified, we retain data as
                                long as it is necessary and relevant for us to
                                achieve the purposes for which the personal data
                                was collected. Personal data required to be
                                retained in order to maintain your Ring Tok
                                account and to enable you the use of Ring Tok
                                Services – these types of data, including your
                                mobile number, and phone address book, will be
                                retained for as long as you maintain your Ring
                                Tok account. <br />
                                Personal data you have voluntarily provided
                                during registration or through your account –
                                these types of data, including your photo, first
                                name and last name, will be retained for as long
                                as you maintain your Ring Tok account.
                              </p>
                            </li>
                          </ul>
                          <h4>Processing Your Personal Data:</h4>
                          <br />
                          <ul>
                            <li>
                              <h4>Cross Platform:</h4>
                              <p>
                                We may also store some aggregated metadata to
                                create Ring Tok features that work across all
                                your devices.
                              </p>
                            </li>
                            <li>
                              <h4>Spam and violation:</h4>
                              <p>
                                To prevent phishing, unsolicited messages, and
                                different sorts of abuse and violations of Ring
                                Tok Terms of Service, our moderators may test
                                messages that had been stated to them by their
                                recipients. If a spam file on a message you sent
                                is shown by our moderators, your account can be
                                restricted from contacting strangers –
                                temporarily or permanently. In case of more
                                severe violations, your account can be banned.
                                We may also use automated algorithms to analyse
                                messages in cloud chats to prevent spam and
                                phishing.
                              </p>
                            </li>
                            <li>
                              <h4>Safety and Security:</h4>
                              <p>
                                Ring Tok supports huge communities which we need
                                to police against abuse and Terms of Service
                                violations. Ring Tok also has more than millions
                                of customers which makes it a beneficial target
                                for spammers. To improve the security of your
                                account, as well as to prevent spam, abuse, and
                                different violations of our Terms of Service, we
                                may collect metadata such as your IP address,
                                gadgets and Ring Tok apps you have used, history
                                of username changes, etc. If collected, this
                                metadata may be saved for six months maximum.
                              </p>
                            </li>
                            <li>
                              <h4>Our Services:</h4>
                              <p>
                                Ring Tok is a cloud service. We will process
                                your data to deliver your cloud chat history,
                                including messages, media and files, to any
                                devices of your choosing without a need for you
                                to use third-party backups or cloud storage.
                              </p>
                            </li>
                            <li>
                              <h4>Advanced features:</h4>
                              <p>
                                We may use a few aggregated data about how you
                                use Ring Tok to build useful features. For
                                example, when you open the Search menu, Ring Tok
                                shows the people you are more likely to message
                                in a field at the top of the screen. To do this,
                                we calculate a rating that indicates which
                                person you message frequently. A similar rating
                                is calculated for inline bots so that the app
                                can suggest the bots you're most likely to apply
                                in the attachment menu (or when you start a new
                                message with “@”).
                              </p>
                            </li>
                            <li>
                              <h4>No Ads Based on User Data:</h4>
                              <p>
                                Unlike other services, we don't use your data
                                for ad targeting or other commercial purposes.
                                Ring Tok only stores the information it needs to
                                function as a secure and feature-rich cloud
                                service.
                              </p>
                            </li>
                          </ul>
                          <h4>
                            Your rights concerning your private information:
                          </h4>
                          <ul>
                            <li>
                              <h4>Your Rights:</h4>
                              <p>
                                Under applicable data protection legislation, in
                                certain circumstances, you have rights
                                concerning your personal data. You have a right
                                to:
                              </p>
                              <ol>
                                <li>
                                  • Request a copy of all your personal data
                                  that we store and to transmit that copy to
                                  another data controller;
                                </li>
                                <li>•Delete or edit your personal data;</li>
                                <li>
                                  • Restrict, or object to, the processing of
                                  your personal data;
                                </li>
                                <li>
                                  • Correct any incorrect or incomplete personal
                                  data we hold on you; and lodge a complaint
                                  with national data protection authorities
                                  regarding our processing of your personal
                                  data.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <h4>Data Settings:</h4>
                              <p>
                                you can control how your data is used (e.g.,
                                delete synced contacts) in Settings / Privacy
                                and Security / Data Settings (using one of our
                                mobile apps). Sadly, if you're not OK with Ring
                                Tok modest minimum requirements, it won't be
                                possible for us to provide you with our
                                Services.
                              </p>
                            </li>
                          </ul>
                          <h4>Deleting Data:</h4>
                          <ul>
                            <li>
                              <h4>Accounts:</h4>
                              <p>
                                If you would like to delete your account, you
                                can do this on the profile page. Deleting your
                                account removes all messages, media, contacts
                                and every other piece of data you store in the
                                Ring Tok cloud. This action must be confirmed
                                via your Ring Tok account and cannot be undone.
                              </p>
                            </li>
                            <li>
                              <h4>Messages:</h4>
                              <p>
                                In cloud chats, you can choose to delete a
                                message for all participants within at least 48
                                hours after sending. Otherwise, deleting a
                                message will delete it from your message
                                history. This means that a copy will stay on the
                                server as part of your partner's message
                                history. As soon as your partner deletes it too,
                                it's gone forever.
                              </p>
                            </li>
                            <li>
                              <h4>Account Self-Destruction :</h4>
                              <p>
                                By default, if you stop using Ring Tok and do
                                not come online for at least 3 months, your
                                account will be deleted along with all messages,
                                media, contacts and every other piece of data
                                you store in the Ring Tok cloud. You can go to
                                Settings to change the exact period after which
                                your inactive account will self-destruct.
                              </p>
                            </li>
                            <li>
                              <h4>Self-Destructing Messages:</h4>
                              <p>
                                Messages in cloud Chats can be ordered to
                                self-destruct. As soon as such a message is seen
                                (2 checks appear), the countdown starts. When
                                the timer expires, both devices participating in
                                a secret chat are instructed to delete the
                                message (photo, video, etc.). The timer is
                                triggered when they are viewed.
                              </p>
                            </li>
                          </ul>
                          <h4>Third Party Services:</h4>
                          <p>
                            Ring Tok websites may contain links that will let
                            you leave the Ring Tok website and access another
                            website. Linked websites are not under the control
                            of Ring Tok and these websites have different
                            privacy policies. The Ring Tok Privacy Policy
                            applies solely to personal information that is
                            acquired on the websites or through your use of the
                            Ring Tok software, Ring Tok products and/or your
                            relationship with Ring Tok, so Ring Tok urges you to
                            be careful when you enter any personal information
                            online. Ring Tok accepts no responsibility or
                            liability for these other websites.
                          </p>
                          <p>
                            If you use any extras, plug-ins or third-party
                            applications together with the Ring Tok software or
                            use any third-party applications or products that
                            incorporate or embed the Ring Tok software
                            (Applications), the provider of such Applications
                            may obtain access to certain personal information
                            about you. Ring Tok does not and cannot control how
                            the provider of Applications may use any personal
                            information connected in connection with such
                            Applications. Please ensure that you review any
                            privacy policies or other terms applicable to the
                            use of such Application before installation.
                          </p>
                          <h4>Cookies:</h4>
                          <p>
                            The only cookies we use are those to operate and
                            provide our Services on the web. We do not use
                            cookies for profiling or advertising. The cookies we
                            use are small text files that allow us to provide
                            and customize our Services, and in doing so provide
                            you with an enhanced user experience. Your browser
                            should allow you to control these cookies, including
                            whether or not to accept them and how to remove
                            them. You may choose to block cookies with your web
                            browser, however, if you do disable these cookies,
                            you will not be able to log in to Ring Tok Web.
                          </p>
                          <h4>Changes to this Privacy Policy:</h4>
                          <p>
                            We will review and may update this Privacy Policy
                            from time to time. Any changes to this Privacy
                            Policy will become effective when we post the
                            revised Privacy Policy on this page
                            <b>www.Ring-tok.org/privacy</b>.Please check our
                            website frequently to see any updates or changes to
                            our Privacy Policy.
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-section">
                  <p>© Ring Tok 2022. All rights are reserved.</p>
                  <p>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
