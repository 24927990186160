import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright-section index-footer">
        <div style={{ textAlign: "center",justifyContent:"center",display:"flex" }}>
          <a
            href="/TermsAndConditions"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            TERMS AND CONDITIONS
          </a>
          <a
            href="/Privacypolicy"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            PRIVACY POLICY
          </a>
          <a
            href="http://blog.ring-tok.com/"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            BLOGS
          </a>
        </div>
        <div style={{justifyContent:"center",display:"flex",textAlign: "center",paddingTop:"30px"}}>
          <a style={{padding:"5px"}} href={"https://www.facebook.com/ringtok1/"}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/fb.png`} />
          </a>
          <a style={{padding:"5px"}} href={"https://twitter.com/tok_ring"}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/tw.png`} />
          </a>
          <a style={{padding:"5px"}} href={"https://www.youtube.com/channel/UC7qbGiH5EugyJgz3ZwvzlYQ"}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/yt.png`} />
          </a>
      </div>
        <p style={{ fontWeight: "bold" }}>
          © Ring Tok 2022. All rights are reserved.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Ring Tok App is only a chatting and communication software. It does
          not have any relations to any other platform with the same name.
        </p>
      </div>


      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>

      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;
