import React, { useEffect, useState } from "react";

const About = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`,
      });
  }, [color]);

  return (
    <>
      <section id="about" className="about" style={bgImg}>
        <div className="about-decor">
          <div className="about-circle1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/team1.png`}
              alt="team1"
            />
          </div>
          <div className="about-circle2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`}
              alt="banner1"
            />
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-md-5">
              <div className="about-contain">
                <div>
                  <h2 className="title">
                    about <span>RINGTOK app</span>
                  </h2>
                  <h2 className="title" style={{ fontWeight: "bold" }}>
                    <p>
                      Stay connected with your friend and family with HD group
                      video and voice calls!
                    </p>
                  </h2>

                  <div className="top-margin">
                    <a href="http://blog.ring-tok.com/">
                      <button
                        type="button"
                        className="btn btn-custom theme-color theme-color"
                      >
                        view more
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-medium-none">
              <div className="about-right">
                <div className="about-phone">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/aboutus.png`}
                    className="img-fluid"
                    alt="aboutus"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
