import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Translated Message</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Translated messages:</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="blog-item">
                  <div style={bgImg} className="blog-block">
                    <div className="blog-box">
                      <div className="overflow-hidden">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/readmore/5.jpg"
                            }
                            alt="blog"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="blog-text">
                    <a
                      href="javascript"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <br />
                      <h2 className="blog-head">Translated messages: </h2>
                    </a>
                    <div className="blog-divider"></div>
                    <div className="blog-description">
                      <p>
                        Based on research, almost 17% of the world’s population
                        speaks English. However, most people with smartphones
                        use chat messenger apps. But most apps let you use just
                        one language for communication. However, if you’re
                        someone interested in extending a hand of friendship to
                        those living abroad you need Ring Talk. The chatting app
                        has a language translation feature that can translate
                        audio and text messages into over a hundred languages.
                        Your messages are translated in real-time so there is
                        continuity in the conversation. With the smooth
                        functioning and quick acting translation feature, it'll
                        seem like you're speaking in the same language. The
                        app's Chat Translation Feature is better than a human
                        translator since it can translate messages into many
                        different languages. Given below are some of the ways
                        you can use the Chat Translation Feature to your
                        benefit:
                      </p>
                      <h3>1. Customers and Sales Agents:</h3>
                      <p>
                        Ring Talk’s Chat Translation Feature simplifies the
                        process of communication between an agent and a customer
                        who speaks a foreign language. Sales will go up when
                        customer queries are answered instantly and clearly. The
                        chat messenger app uses an innovative service that
                        utilizes hi-tech machine learning technology. It
                        translates audio and text messages in real-time. Using
                        the chat translation app will be advantageous for both,
                        the agent, and the customer since they won’t have a hard
                        time understanding each other.
                      </p>
                      <h3>2. Health care professionals and patients:</h3>
                      <p>
                        No matter where you're from, if someone you know is in a
                        critical condition, you should rush to the nearest
                        hospital. But what if you get there and realize no one
                        understands you because you're a foreigner? At a time
                        like this, you can try and communicate using Ring Talk.
                        Use the app to translate audio recordings into the
                        language of the health care professionals. With this
                        feature, you can ensure the patient gets proper care and
                        attention.
                      </p>
                      <h3>3. Professors and students:</h3>
                      <p>
                        Students around the world now prefer to take online
                        classes from renowned International Universities. This
                        helps them save time and commute expenses. Professors
                        try to be as thorough as possible online but some
                        students still face difficulties due to language
                        differences. They can use Ring Talk to exchange notes
                        with fellow peers. Ring Talk will translate their
                        messages for them so they don’t have to use separate
                        software.
                      </p>
                      <h3>4. Personal and professional interactions:</h3>
                      <p>
                        If you’re someone who travels a lot, it’s highly likely
                        for you to come across people who speak a different
                        language. And whatever your reasons for traveling,
                        personal or professional, it’s inevitable that you’ll be
                        required to interact with foreigners. But how do you do
                        it if you don’t know their language? Simply download
                        Ring Talk and get the person you wish to talk to, to do
                        the same. Once both of you start using the app, you’ll
                        have no trouble communicating.
                      </p>
                      <h3>5. Romance and love:</h3>
                      <p>
                        Those who believe in love just follow their heart
                        wherever it takes them. You can find love in your
                        neighborhood or across the border, but to take things
                        forward communication is necessary. You can have a
                        successful relationship with someone who speaks a
                        different language by using Ring Talk. The chatting app
                        has some great stickers and GIFs, along with the Chat
                        Translation Feature, to make your conversations more
                        enjoyable.
                      </p>
                      <p>
                        Ring Talk is a chat messenger app that has several
                        amazing features for users. One of its most prominent
                        features is the Chat Translation Feature. There are not
                        many chatting apps that enable overseas communication.
                        But with Ring Talk, you can make{" "}
                        <a
                          href="/clear-calls"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          crystal clear calls
                        </a>{" "}
                        anywhere in the world and easily chat with foreigners
                        too. It is a one-of-a-kind app that will help you
                        overcome all your communication challenges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>
                    © Ring Tok 2022. All rights are reserved.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
