import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Crystal Clear Calls</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Crystal clear calls:</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="blog-item">
                  <div style={bgImg} className="blog-block">
                    <div className="blog-box">
                      <div className="overflow-hidden">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/readmore/1.jpg"
                            }
                            alt="blog"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="blog-text">
                    <a
                      href="javascript"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <br />
                      <h2 className="blog-head">Crystal clear calls: </h2>
                    </a>
                    <div className="blog-divider"></div>
                    <div className="blog-description">
                      <p>
                        Making overseas calls was once a hectic and costly
                        process, however, thanks to chat messenger apps, things
                        have changed significantly. Now you just need the
                        internet, a smartphone or laptop, and a suitable
                        chatting app. With Ring Talk, you can make free-of-cost,
                        unlimited crystal clear calls anywhere in the world.
                        Listed below are some of the ways you can make good use
                        of the app’s calling feature:
                      </p>
                      <h3>1. Make one-to-one dedicated calls:</h3>
                      <p>
                        Most of us are used to making one-to-one calls,
                        especially the slightly older generation. This habit was
                        formed when we used PTCL phones to make important calls.
                        Later, when mobile phones came out, there was still no
                        group calling feature. So we just dial one number out of
                        habit and it connects us with that one special person we
                        want to dedicate our time to. However, we’re also used
                        to making very lengthy calls which would have been very
                        expensive in the past. But now, with apps like Ring
                        Talk, you can make both local and overseas calls without
                        any cost.
                      </p>
                      <h3>2. Call four people simultaneously:</h3>
                      <p>
                        While calling more than one person was impossible at one
                        time, you can now call four people at a time with Ring
                        Talk. This feature is very useful if you want to
                        maintain a healthy relationship with friends and family.
                        You can simultaneously give your precious time to four
                        people so no one feels left out. The feature is also
                        very useful for work-related discussions and meetings.
                        For in-person meetings, you have to dress up, get your
                        bag and keys, check the fuel in your car, and then head
                        out. Ring Talk will help you save on time and fuel by
                        conducting your meeting over a call.
                      </p>
                      <h3>3. Exchange information via audio calls:</h3>
                      <p>
                        You can easily transfer important information with Ring
                        Talk's audio call feature. No matter what your
                        occupation, there's always a need for quick and smooth
                        communication. Ring Talk gives you just that! With this
                        chat messenger app, you can make crystal clear calls and
                        get work done more efficiently. When you can communicate
                        with clarity, you automatically become more productive.
                        The people you connect with throughout the day are also
                        happier when they can hear you clearly since then
                        there’s less room for misunderstandings.
                      </p>
                      <h3>4. Say it all on video call:</h3>
                      <p>
                        When there’s a lot on your mind and you need to let it
                        out, meeting a friend helps. But what if you can’t meet
                        your friend in person? Then the best way to communicate
                        is to use your chatting app and connect via video call.
                        Ring Talk’s video calling feature is great for making
                        video calls. The audio-visual experience is as good as
                        the real thing since you can see and hear the person
                        you’re talking to. You can stay on call for as long as
                        you want since there are no charges.
                      </p>
                      <h3>5. Make unlimited calls:</h3>
                      <p>
                        There are some people in our lives who we can talk to
                        for hours on end. We forget what time of the day it is
                        when we’re chatting with them. Nothing else seems
                        important when you’re talking to that someone special.
                        It’s only after you hang up that you realize how much
                        money you spent on just one call and that pulls you out
                        of your reverie. With Ring Talk, you don’t have to worry
                        about overspending on your calls. You can make free
                        calls if you have WIFI and even if you don’t it’ll use
                        up very little mobile data.
                      </p>
                      <p>
                        Ring Talk is a chat messenger app that aims to give its
                        users the best calling experience. The chatting app has
                        many other features to make communication convenient and
                        efficient for its users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>
                    © Ring Tok 2022. All rights are reserved.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
