import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const download = () => {
  const mystyle = {
    paddingTop: "20vh",
    textAlign: "center",
    fontWeight: "bold",
  };
  const mystyle2 = {
    textAlign: "center",
  };
  const tablest = {
    marginLeft: "auto",
    marginRight: "auto",
  };
  const stores = {
    padding: "10vh",
    display: "Block",
    textAlign: "center",
  };
  const padd = {
    padding: "1vh",
  };

  return (
    <>
      <Helmet>
        <title>Ringtok | Download</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <h2 style={mystyle}>Download Stores</h2>
      <div style={mystyle2}>
        <h3>App stores where you can download the awesome Ring Tok app.</h3>
        <table style={tablest}>
          <tr>
            <th>
              <div style={stores}>
                <img src="/assets/images/applestore.png"></img>
                <h4 style={padd}>App Store</h4>
                <a href="https://apps.apple.com/us/app/ringtok-messenger/id1633189713">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
            <th>
              <div style={stores}>
                <img src="/assets/images/playstore.png"></img>
                <h4 style={padd}>Play Store</h4>
                <a href="https://play.google.com/store/apps/details?id=com.messenger.ringtok">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
            <th>
              <div style={stores}>
                <img src="/assets/images/amazonstore.png"></img>
                <h4 style={padd}>Amazon Store</h4>
                <a href="">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
          </tr>
          <tr>
            <th>
              <div style={stores}>
                <img src="/assets/images/galaxystore.png"></img>
                <h4 style={padd}>Galaxy Store</h4>
                <a href="https://galaxystore.samsung.com/detail/com.messenger.ringtok">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
            <th>
              <div style={stores}>
                <img src="/assets/images/vivostore.png"></img>
                <h4 style={padd}>Vivo Store</h4>
                <a href="">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
            <th>
              <div style={stores}>
                <img src="/assets/images/huaweistore.png"></img>
                <h4 style={padd}>Huawei Store</h4>
                <a href="https://appgallery.huawei.com/app/C106948831">
                  <button className="btn btn-custom theme-color theme-color">
                    Download
                  </button>
                </a>
              </div>
            </th>
          </tr>
        </table>
      </div>
    </>
  );
};

export default download;
