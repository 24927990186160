import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
  });

  const color = localStorage.getItem("color");
  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`,
      });
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`,
      });
  }, [color]);

  return (
    <div>
      <Helmet>
        <title>Ringtok | Private And Group Chats</title>
      </Helmet>
      <Navbar
        style={{ backgroundColor: "white" }}
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt="logo"
            />
          </NavbarBrand>
        </div>
      </Navbar>
      <div className="page-margin">
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span> Private and group chats:</span>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center"></div>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="blog-item">
                  <div style={bgImg} className="blog-block">
                    <div className="blog-box">
                      <div className="overflow-hidden">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/readmore/2.jpg"
                            }
                            alt="blog"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="blog-text">
                    <a
                      href="javascript"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <br />
                      <h2 className="blog-head">Private and group chats: </h2>
                    </a>
                    <div className="blog-divider"></div>
                    <div className="blog-description">
                      <p>
                        Humans were born into this world to live with and among
                        other humans. And for living in harmony with others,
                        it’s necessary to be able to communicate effectively.
                        It’s human nature to continue searching for ways to
                        improve communication with the people around us,
                        especially those that we like. Ring Talk has a similar
                        purpose. It aims to help people grow closer to one
                        another by helping them communicate smoothly. This chat
                        messenger app lets you send free unlimited messages to
                        other users, anywhere in the world. You can share
                        photos, audio recordings, videos, and other documents
                        without worrying about space issues using this chatting
                        app. One of the most commendable features of this app is
                        its messaging feature. Given below are some of the
                        reasons why Ring Talk is a great option for you when if
                        you’re someone who likes to communicate via messages:
                      </p>
                      <h3>1. Unlimited messages:</h3>
                      <p>
                        Messaging is the easiest way to stay in touch with the
                        people you care about. Ring Talk lets users send
                        unlimited messages to friends, family, colleagues,
                        neighbors, second cousins, and several others. You no
                        longer have an excuse to stay isolated and cut off from
                        people who matter for long lengths of time. With Ring
                        Talk, everyone you know is just a message away!
                      </p>
                      <h3>2. Disappearing messages:</h3>
                      <p>
                        It’s only natural to feel hurt and angry at times and
                        feel the urge to vent out. We often vent out by
                        confiding in a friend about our personal problems,
                        either over a call or in messages. But if the messages
                        we share in our state of vulnerability get into the
                        wrong hands, we feel weak and helpless. To protect its
                        users from experiencing stressful situations, Ring Talk
                        has come up with a ‘disappearing messages’ feature.
                        Messages will immediately disappear after the person
                        they were intended for has read them.
                      </p>
                      <h3>3. Language barriers:</h3>
                      <p>
                        People who travel a lot, especially out of the country,
                        are very likely to come across foreigners. But what if
                        you fall for someone who speaks a different language?
                        You needn’t feel hopeless, as long as you’ve got Ring
                        Talk. The chatting app has a{" "}
                        <a
                          href="/translated-messages"
                          style={{
                            color: "rgb(237, 91, 8)",
                            fontWeight: "bold",
                          }}
                        >
                          translation feature
                        </a>{" "}
                        that can translate text and audio messages into more
                        than a hundred languages.
                      </p>
                      <h3>4. Organized chats:</h3>
                      <p>
                        Going through individual and group chats all at the same
                        time can be quite a hassle. You can separate your
                        individual chats from group chats using Ring Talk’s
                        Archive Chat feature. So when you’re in the mood for a
                        one-to-one chat, you can simply tap on the Individual
                        chats category. And when you’re in the mood for a party,
                        select Group chat and have a ton of fun!
                      </p>
                      <h3>5. Expressive messages:</h3>
                      <p>
                        Some people know how to decorate their messages with
                        words while some prefer to keep their messages short.
                        However, for some relationships, you need to add more
                        feeling to your messages. But here’s the thing! If you
                        want to make your messages more emotional, you can use
                        stickers, emoticons, and GIFs. With Ring Talk, you can
                        beautify your messages without many words!
                      </p>
                      <h3>6. Ample space:</h3>
                      <p>
                        We make memories in the real world and share them with
                        each other in the virtual world so we can revive them.
                        With Ring Talk, you can relive beautiful memories
                        without worrying about the size of data being shared.
                        Send unlimited pictures and audio and video recordings
                        to your loved ones. Let them know how much you cherish
                        the time you’ve spent with them.
                      </p>
                      <h3>7. Document sharing:</h3>
                      <p>
                        Working individuals often have to share important
                        documents with one another. Students need to exchange
                        notes and submit assignments. For any kind of job,
                        freelance or in-house, there’s always a need to share
                        essential documents. With Ring Talk, you can share files
                        and documents in both, word and pdf format. With this
                        chat messenger app, you can share unlimited files and
                        documents with fellow students, colleagues, clients,
                        etc.
                      </p>
                      <p>
                        Ring Talk is a chat messenger app that makes it easy for
                        users to stay on top of things. It enables users to
                        maintain healthy ties with people from all walks of life
                        so they can learn and grow in the best possible way. The
                        chatting app provides users with features that enhance
                        free-flowing and smooth communication.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>
                    © Ring Tok 2022. All rights are reserved.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Ring Tok App is only a chatting and communication software.
                    It does not have any relations to any other platform with
                    the same name.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
